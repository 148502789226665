import React, { useEffect, useState } from "react";
import { Buildings, Profile2User, User } from "iconsax-react";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import CardStatsSuperAdmin from "./components/CardStatsSuperAdmin";
import TabsFilter from "./components/TabsFilter";
import WeeklyCenterRegisteration from "./components/WeeklyCenterRegisteration";

import { Filters } from "../../types/IDashboard.Interface";
import { useSuperAdminDashboard } from "../../hooks/useSuperAdminDashboard";
import { CardData, WeekData } from "../../types/ICardData.Interface";
import { Tabs } from "../../enums/tabs.enum";
import { current } from "@reduxjs/toolkit";
import RegistrationChartMonthly from "./components/RegistrationChartMonthly";
import RegistrationChartYearly from "./components/RegistrationChartYearly";

const cardDataDefault: CardData[] = [
  {
    id: 1,
    title: "All Center Registrations",
    value: 0,
    trend: 0,
    icon: <Buildings size="24" className="text-primary" />,
    iconBgColor: "#FFFAF8",
  },
  {
    id: 2,
    title: "Teacher Registrations",
    value: 0,
    trend: 0,
    icon: <User size="24" className="text-primary" />,
    iconBgColor: "#F8F8FF",
  },
  {
    id: 3,
    title: "Parent Registrations",
    value: 0,
    trend: 0,
    icon: <Profile2User size="24" className="text-primary" />,
    iconBgColor: "#FFF8FA",
  },
];

const data1 = [
  { name: "0", barValue: 13, lineValue: 0 },
  { name: "10", barValue: 13, lineValue: 100 },
  { name: "20", barValue: 13, lineValue: 200 },
  { name: "30", barValue: 13, lineValue: 500 },
  { name: "40", barValue: 13, lineValue: 1000 },
  { name: "50", barValue: 13, lineValue: 500 },
  { name: "60", barValue: 13, lineValue: 1000 },
];

const data2: WeekData[] = [
  { name: "0", barValue: 25, lineValue: 50 },
  { name: "10", barValue: 18, lineValue: 150 },
  { name: "20", barValue: 10, lineValue: 300 },
  { name: "30", barValue: 30, lineValue: 1000 },
  { name: "50", barValue: 15, lineValue: 5000 },
];

const chartData = [
  { day: "Day 1", week: "Week 1", barValue: 13, lineValue: 13 },
  { day: "Day 2", week: "Week 1", barValue: 15, lineValue: 28 },
  { day: "Day 3", week: "Week 1", barValue: 18, lineValue: 46 },
  { day: "Day 4", week: "Week 1", barValue: 20, lineValue: 66 },
  { day: "Day 5", week: "Week 1", barValue: 22, lineValue: 88 },
  { day: "Day 6", week: "Week 1", barValue: 25, lineValue: 113 },
  { day: "Day 7", week: "Week 1", barValue: 30, lineValue: 143 },
  { day: "Day 8", week: "Week 2", barValue: 35, lineValue: 178 },
  { day: "Day 9", week: "Week 2", barValue: 40, lineValue: 218 },
  { day: "Day 10", week: "Week 2", barValue: 45, lineValue: 263 },
  { day: "Day 11", week: "Week 2", barValue: 50, lineValue: 313 },
  { day: "Day 12", week: "Week 2", barValue: 55, lineValue: 368 },
  { day: "Day 13", week: "Week 2", barValue: 60, lineValue: 428 },
  { day: "Day 14", week: "Week 2", barValue: 65, lineValue: 493 },
  { day: "Day 15", week: "Week 3", barValue: 70, lineValue: 563 },
  { day: "Day 16", week: "Week 3", barValue: 75, lineValue: 638 },
  { day: "Day 17", week: "Week 3", barValue: 80, lineValue: 718 },
  { day: "Day 18", week: "Week 3", barValue: 85, lineValue: 803 },
  { day: "Day 19", week: "Week 3", barValue: 90, lineValue: 893 },
  { day: "Day 20", week: "Week 3", barValue: 95, lineValue: 988 },
  { day: "Day 21", week: "Week 3", barValue: 100, lineValue: 1088 },
  { day: "Day 22", week: "Week 4", barValue: 105, lineValue: 1193 },
  { day: "Day 23", week: "Week 4", barValue: 110, lineValue: 1303 },
  { day: "Day 24", week: "Week 4", barValue: 115, lineValue: 1418 },
  { day: "Day 25", week: "Week 4", barValue: 120, lineValue: 1538 },
  { day: "Day 26", week: "Week 4", barValue: 125, lineValue: 1663 },
  { day: "Day 27", week: "Week 4", barValue: 130, lineValue: 1793 },
  { day: "Day 28", week: "Week 4", barValue: 135, lineValue: 1928 },
];

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.WEEKLY);

  const [cardData, setCardData] = useState<CardData[]>(cardDataDefault);

  const [centerWeekData, setCenterWeekData] = useState<WeekData[]>([]);
  const [teacherWeekData, setTeacherWeekData] = useState<WeekData[]>([]);
  const [parentWeekData, setParentWeekData] = useState<WeekData[]>([]);
  const [monthlyToWeekData, setMonthlyToWeekData] = useState<any>([]);

  const dashboardFilters: Filters = {
    view: activeTab,
  };
  const { data: dashboard }: any = useSuperAdminDashboard(dashboardFilters);

  const updateCardData = (dashboard: any) => {
    setCardData((prevCardData) =>
      prevCardData.map((card) => {
        if (card.id === 1) {
          return {
            ...card,
            value: dashboard?.data?.totalCenters || 0,
            trend: dashboard?.data?.stats?.centerChange || 0,
          };
        }
        if (card.id === 2) {
          return {
            ...card,
            value: dashboard?.data?.totalTeachers || 0,
            trend: dashboard?.data?.stats?.teacherChange || 0,
          };
        }
        if (card.id === 3) {
          return {
            ...card,
            value: dashboard?.data?.totalParents || 0,
            trend: dashboard?.data?.stats?.parentChange || 0,
          };
        }
        return card;
      })
    );
  };

  const transformWeekData = (
    graphData: any,
    type: "centers" | "teachers" | "parents"
  ) => {
    return graphData.map((data: any) => ({
      barValue: data?.dailyRegistrations[type],
      lineValue: data?.totalRegistrationsTillDay[type],
      name: data?.day?.split("-")[2],
    }));
  };

  // const getWeeklyData = (dailyData: any) => {
  //   // Initialize an empty array for weeks
  //   const weeks = [];
  //   let currentWeek: any = {
  //     startDate: null,
  //     dailyRegistrations: {
  //       centers: 0,
  //       teachers: 0,
  //       parents: 0,
  //     },
  //     totalRegistrationsTillDay: {
  //       centers: 0,
  //       teachers: 0,
  //       parents: 0,
  //     },
  //   };
  //   dailyData.forEach((dayData: any, index: any) => {
  //     const day = dayData.day;
  //     const dayOfWeek = new Date(dayData.day).getDay();
  //     const startOfWeek = new Date(day);
  //     startOfWeek.setDate(new Date(dayData.day).getDate() - dayOfWeek + 1);

  //     if (
  //       !currentWeek.startDate ||
  //       currentWeek.startDate.getTime() !== startOfWeek.getTime()
  //     ) {
  //       if (currentWeek.startDate) {
  //         weeks.push(currentWeek);
  //       }

  //       currentWeek = {
  //         startDate: startOfWeek,
  //         dailyRegistrations: {
  //           centers: 0,
  //           teachers: 0,
  //           parents: 0,
  //         },
  //         totalRegistrationsTillDay: {
  //           centers: 0,
  //           teachers: 0,
  //           parents: 0,
  //         },
  //       };
  //     }
  //     currentWeek.dailyRegistrations.centers +=
  //       dayData.dailyRegistrations.centers;
  //     currentWeek.dailyRegistrations.teachers +=
  //       dayData.dailyRegistrations.teachers;
  //     currentWeek.dailyRegistrations.parents +=
  //       dayData.dailyRegistrations.parents;
  //     currentWeek.totalRegistrationsTillDay.centers =
  //       dayData.totalRegistrationsTillDay.centers;
  //     currentWeek.totalRegistrationsTillDay.teachers =
  //       dayData.totalRegistrationsTillDay.teachers;
  //     currentWeek.totalRegistrationsTillDay.parents =
  //       dayData.totalRegistrationsTillDay.parents;
  //   });

  //   if (currentWeek.startDate) {
  //     weeks.push(currentWeek);
  //   }

  //   return weeks;
  // };

  // Usage
  // const weeklyData = getWeeklyData(response.graphData);
  // console.log(weeklyData);

  // const getWeeklyData = (dailyData: any) => {
  //   // Initialize an empty array for weeks
  //   const weeks = [];
  //   let currentWeek: any = {
  //     weekNumber: "",
  //     dailyRegistrations: {
  //       centers: 0,
  //       teachers: 0,
  //       parents: 0,
  //     },
  //     totalRegistrationsTillDay: {
  //       centers: 0,
  //       teachers: 0,
  //       parents: 0,
  //     },
  //   };

  //   dailyData.forEach((dayData: any, index: any) => {
  //     // Calculate the week number by dividing the index by 7 (every 7 days is a new week)
  //     const weekNumber = Math.floor(index / 7) + 1;

  //     // If it's the first entry of a new week, push the previous week to the weeks array and start a new week
  //     if (currentWeek.weekNumber !== `week ${weekNumber}`) {
  //       if (currentWeek.weekNumber) {
  //         weeks.push(currentWeek); // Push the current week to the weeks array
  //       }

  //       // Start a new week with updated week number
  //       currentWeek = {
  //         weekNumber: `week ${weekNumber}`,
  //         dailyRegistrations: {
  //           centers: 0,
  //           teachers: 0,
  //           parents: 0,
  //         },
  //         totalRegistrationsTillDay: {
  //           centers: 0,
  //           teachers: 0,
  //           parents: 0,
  //         },
  //       };
  //     }

  //     // Add the daily registrations data to the current week
  //     currentWeek.dailyRegistrations.centers =
  //       dayData.dailyRegistrations.centers;
  //     currentWeek.dailyRegistrations.teachers =
  //       dayData.dailyRegistrations.teachers;
  //     currentWeek.dailyRegistrations.parents =
  //       dayData.dailyRegistrations.parents;
  //     currentWeek.totalRegistrationsTillDay.centers =
  //       dayData.totalRegistrationsTillDay.centers;
  //     currentWeek.totalRegistrationsTillDay.teachers =
  //       dayData.totalRegistrationsTillDay.teachers;
  //     currentWeek.totalRegistrationsTillDay.parents =
  //       dayData.totalRegistrationsTillDay.parents;
  //   });

  //   // Push the last week to the weeks array
  //   if (currentWeek.weekNumber) {
  //     weeks.push(currentWeek);
  //   }

  //   return weeks;
  // };

  useEffect(() => {
    if (dashboard) {
      updateCardData(dashboard);
      if (activeTab === Tabs.WEEKLY) {
        setCenterWeekData(() =>
          transformWeekData(dashboard?.data?.graphData, "centers")
        );
        setTeacherWeekData(() =>
          transformWeekData(dashboard?.data?.graphData, "teachers")
        );
        setParentWeekData(() =>
          transformWeekData(dashboard?.data?.graphData, "parents")
        );
      }
      // else if (activeTab === Tabs.MONTHLY) {
      //   setMonthlyToWeekData(() => getWeeklyData(dashboard?.data?.graphData));
      //   console.log(monthlyToWeekData);
      // }
    }
  }, [dashboard,activeTab,dashboardFilters]);

  // console.log(dashboard);

  const getTabContent = () => {
    switch (activeTab) {
      case Tabs.WEEKLY:
        return (
          <div className="space-y-8">
            <WeeklyCenterRegisteration
              title="Center Registration"
              containerColor="#1577DA"
              data={centerWeekData}
            />
            <WeeklyCenterRegisteration
              title="Teacher Registration"
              containerColor="#00C79F"
              data={teacherWeekData}
            />
            <WeeklyCenterRegisteration
              title="Parent Registration"
              containerColor="#6436C8"
              data={parentWeekData}
            />
          </div>
        );
      case Tabs.MONTHLY:
        return (
          <div className="space-y-8">
            <RegistrationChartMonthly
              weekData={monthlyToWeekData}
              dayData={dashboard?.data?.graphData}
              title={"Center Registration"}
              barcolor="#1577DA"
              linecolor="#1577DA"
            />
            <RegistrationChartMonthly
              weekData={monthlyToWeekData}
              dayData={dashboard?.data?.graphData}
              title={"Teacher Registration"}
              barcolor="#00C79F"
              linecolor="#00C79F"
            />
            <RegistrationChartMonthly
              weekData={monthlyToWeekData}
              dayData={dashboard?.data?.graphData}
              title={"Parent Registration"}
              barcolor="#6436C8"
              linecolor="#6436C8"
            />
          </div>
        );
        // case "Quarterly":
        return (
          <div className="space-y-8">
            <RegistrationChartMonthly
              weekData={monthlyToWeekData}
              dayData={dashboard?.data?.graphData}
              title={"Center Registration"}
              barcolor="#1577DA"
              linecolor="#1577DA"
            />
            <RegistrationChartMonthly
              weekData={monthlyToWeekData}
              dayData={dashboard?.data?.graphData}
              title={"Teacher Registration"}
              barcolor="#00C79F"
              linecolor="#00C79F"
            />
            <RegistrationChartMonthly
              weekData={monthlyToWeekData}
              dayData={dashboard?.data?.graphData}
              title={"Parent Registration"}
              barcolor="#6436C8"
              linecolor="#6436C8"
            />
          </div>
        );
      case Tabs.YEARLY:
        return (
          <div className="space-y-8">
            <RegistrationChartYearly
              weekData={monthlyToWeekData}
              dayData={dashboard?.data?.graphData}
              title={"Center Registration"}
              barcolor="#1577DA"
              linecolor="#1577DA"
            />
            <RegistrationChartYearly
              weekData={monthlyToWeekData}
              dayData={dashboard?.data?.graphData}
              title={"Teacher Registration"}
              barcolor="#00C79F"
              linecolor="#00C79F"
            />
            <RegistrationChartYearly
              weekData={monthlyToWeekData}
              dayData={dashboard?.data?.graphData}
              title={"Parent Registration"}
              barcolor="#6436C8"
              linecolor="#6436C8"
            />
          </div>
        );
      case Tabs.CUSTOM:
        return (
          <div className="space-y-8">
            <RegistrationChartMonthly
              weekData={monthlyToWeekData}
              dayData={dashboard?.data?.graphData}
              title={"Center Registration"}
              barcolor="#1577DA"
              linecolor="#1577DA"
            />
            <RegistrationChartMonthly
              weekData={monthlyToWeekData}
              dayData={dashboard?.data?.graphData}
              title={"Teacher Registration"}
              barcolor="#00C79F"
              linecolor="#00C79F"
            />
            <RegistrationChartMonthly
              weekData={monthlyToWeekData}
              dayData={dashboard?.data?.graphData}
              title={"Parent Registration"}
              barcolor="#6436C8"
              linecolor="#6436C8"
            />
          </div>
        );
      default:
        return <p>Unknown tab.</p>;
    }
  };

  return (
    <DashboardWrapper>
      <div className="px-6 pt-5">
        <div className="grid grid-cols-3 gap-3 mb-6">
          {cardData.map((card) => (
            <CardStatsSuperAdmin
              key={card.id}
              title={card.title}
              value={card.value}
              trend={card.trend}
              icon={card.icon}
              iconBgColor={card.iconBgColor}
            />
          ))}
        </div>
        <div className="grid grid-cols-2 gap-8">
          <div>
            <h2 className="font-semibold text-2xl text-secondary">Overview</h2>
          </div>
          <div>
            <TabsFilter activeTab={activeTab} setActiveTab={setActiveTab} />
          </div>
        </div>
        <div className="mt-6">{getTabContent()}</div>
      </div>
    </DashboardWrapper>
  );
};

export default AdminDashboard;
