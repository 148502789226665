import React, { FC, useEffect, useState } from "react";
import { IoIosAddCircleOutline } from "react-icons/io";
import { RiDeleteBin3Line } from "react-icons/ri";
import TimeDropdown from "../../components/dropdowns/TimeDropdown";
import InputField from "../../components/inputsFields/InputField";
import { timeOptions } from "../../constants/constantValues";
import { initPartTimeSessionEssentials } from "../../constants/initialState";
import { IPartTimeSlots } from "../../types/IClassroomSetup.interface";
import { FiEdit } from "react-icons/fi";
import { MdInfoOutline } from "react-icons/md";
import { TooltipComponent } from "../../../shared/components/tooltip/Tooltip";

const tooltips = {
  partTime: {
    id: "partTime",
    content: `<p>Please note that Part-Time session timings cannot overlap. Also, Before-After/School Age programs are NOT defined as Part-Time so please do NOT include them as Part-Time.</p>`,
  },
};

interface Props {
  partTimeSessions: IPartTimeSlots[];
  setPartTimeSessions: React.Dispatch<
    React.SetStateAction<IPartTimeSlots[]>
  >;
  havePartTimeSessions: boolean;
  setHavePartTimeSessions: (check: boolean) => void;
  handleCancelPartTimeSession: () => void;
  handleSave: () => Promise<void>;
  handleNonSeqClasses: () => Promise<void>;
}

const PartTimeSession: FC<Props> = ({
  partTimeSessions,
  setPartTimeSessions,
  havePartTimeSessions,
  setHavePartTimeSessions,
  handleCancelPartTimeSession,
  handleSave,
  handleNonSeqClasses,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleEdit = () => {
    setIsEdit((prev) => !prev);
  };

  const handleHavePartTimeSession = (check: boolean) => {
    setHavePartTimeSessions(check);
    if (!check) {
      setPartTimeSessions([]);
    } else setPartTimeSessions([initPartTimeSessionEssentials]);
  };

  const handlePartTimeSessions = (
    fieldName: string,
    value: string | number,
    index: number
  ) => {
    setPartTimeSessions((prev) => {
      const updatedSessions = [...prev];
      updatedSessions[index] = {
        ...updatedSessions[index],
        [fieldName]: value,
      };
      return updatedSessions;
    });
  };

  const handleAddPartTimeSession = (index: number) => {
    setPartTimeSessions((prev) => {
      const updatedSessions = [...prev];
      updatedSessions.splice(index + 1, 0, initPartTimeSessionEssentials);
      return updatedSessions;
    });
  };

  const handleRemovePartTimeSession = (index: number) => {
    setPartTimeSessions((prev) => {
      const updatedSessions = [...prev];
      updatedSessions.splice(index, 1);
      return updatedSessions;
    });
  };

  const handleCancel = () => {
    handleCancelPartTimeSession();
    setIsEdit(false);
  };

  const validateSessions = () => {
    const hasErrors =
      (partTimeSessions?.length === 0 && havePartTimeSessions) ||
      partTimeSessions.some(
        (session) =>
          !session?.name?.trim() || !session.startTime || !session.endTime
      );
    setIsDisabled(hasErrors);
  };

  const handleSaveClick = () => {
    console.log("check partTime: ", partTimeSessions);
    handleSave();
    handleNonSeqClasses();
    setIsEdit(false);
  };

  useEffect(() => {
    validateSessions();
  }, [partTimeSessions]);

  function handleSectionChange(
    index: any,
    arg1: string,
    arg2: string,
    selectedTime: string
  ) {
    throw new Error("Function not implemented.");
  }

  return (
    <>
      <div>
        <h3 className="text-xl text-secondary font-semibold mb-3 flex items-start gap-2">
          Part-time Session{" "}<span data-tooltip-id={tooltips.partTime.id} ><MdInfoOutline className="text-primary w-[18px] h-[18px]" /></span>
        </h3>
        <TooltipComponent
          id={tooltips.partTime.id}
          content={tooltips.partTime.content}
          isWhite={true}
        />
        <div className="bg-white p-6 rounded-xl mb-3">
          <div className="flex items-center justify-between">
            <h3 className="text-base text-secondary mb-3">
              Do you have part-time students in your center? (please do NOT
              include Before-After/School Age programs here)?{" "}
            </h3>
            {!isEdit && (
              <button
                onClick={handleEdit}
                className="text-statusColor inline-flex items-center gap-2 text-lg"
              >
                Modify <FiEdit />
              </button>
            )}
          </div>
          <div className="flex items-center gap-6 mb-6">
            <div className="flex items-center gap-2">
              <input
                disabled={!isEdit}
                type="radio"
                className={`w-5 h-5 border-2 ${isEdit ? "cursor-pointer" : ""
                  } border-gray-400 appearance-none rounded-full flex items-center justify-center checked:border-primary focus:outline-none checked:bg-white checked:before:content-[''] checked:before:block checked:before:rounded-full checked:before:bg-primary checked:before:w-3 checked:before:h-3`}
                checked={havePartTimeSessions}
                onChange={() => {
                  handleHavePartTimeSession(true);
                }}
              />

              <label htmlFor="" className="text-base text-secondaryVariant">
                Yes
              </label>
            </div>
            <div className="flex items-center gap-2">
              <input
                disabled={!isEdit}
                type="radio"
                className={`w-5 h-5 border-2 ${isEdit ? "cursor-pointer" : ""
                  } border-gray-400 appearance-none rounded-full flex items-center justify-center checked:border-primary focus:outline-none checked:bg-white checked:before:content-[''] checked:before:block checked:before:rounded-full checked:before:bg-primary checked:before:w-3 checked:before:h-3`}
                checked={!havePartTimeSessions}
                onChange={() => {
                  handleHavePartTimeSession(false);
                }}
              />
              <label htmlFor="" className="text-base text-secondaryVariant">
                No
              </label>
            </div>
          </div>
          {havePartTimeSessions && (
            <div>
              <h3 className="text-base text-secondary font-medium mb-4">
                Please select up to 4 part-time sessions at your center.
              </h3>
              <div className="grid grid-cols-6 gap-3 mb-9">
                {partTimeSessions?.map(
                  (session: IPartTimeSlots, index: number) => (
                    <div
                      className="col-span-6 flex items-center gap-6"
                      key={index}
                    >
                      <div className="grid grid-cols-12 gap-3 bg-secondaryNeutral py-1 px-2 rounded-lg">
                        <div className="col-span-4">
                          <InputField
                            disabled={!isEdit}
                            label="Session Name"
                            type="text"
                            name="name"
                            placeholder=""
                            value={session?.name}
                            onChange={(e) =>
                              handlePartTimeSessions(
                                e.target.name,
                                e.target.value,
                                index
                              )
                            }
                            required
                          />
                        </div>
                        <div className="col-span-4">
                          <div>
                            <TimeDropdown
                              key={session?.startTime}
                              disabled={!isEdit}
                              times={timeOptions}
                              label="Start Time"
                              required
                              placeholder="Set start time"
                              value={session?.startTime}
                              onSelectTime={(selectedTime) => {
                                handlePartTimeSessions(
                                  "startTime",
                                  selectedTime,
                                  index
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-span-4 flex items-end w-full gap-4">
                          <div className="w-full">
                            <TimeDropdown
                              key={session?.endTime}
                              disabled={!isEdit}
                              times={timeOptions}
                              label="End Time "
                              required
                              placeholder="Set End time"
                              value={session?.endTime}
                              onSelectTime={(selectedTime) => {
                                handlePartTimeSessions(
                                  "endTime",
                                  selectedTime,
                                  index
                                );
                              }}
                            />
                          </div>
                          <button
                            className={`${!isEdit ? "text-[#DADADA]" : "text-dangerAlert"
                              } bg-white rounded-full p-1`}
                            onClick={() => {
                              handleRemovePartTimeSession(index);
                            }}
                          >
                            <RiDeleteBin3Line className="w-6 h-6 " />
                          </button>
                        </div>
                      </div>
                      {index < 3 && (
                        <div>
                          <button
                            className={`text-base  ${!isEdit ? "text-[#DADADA]" : "text-statusColor"
                              } flex items-center gap-2 font-medium `}
                            onClick={() => {
                              handleAddPartTimeSession(index);
                            }}
                          >
                            Add <IoIosAddCircleOutline className="w-6 h-6" />
                          </button>
                        </div>
                      )}
                    </div>
                  )
                )}
                {partTimeSessions?.length === 0 && (
                  <button
                    className={`text-base  ${!isEdit ? "text-[#DADADA]" : "text-statusColor"
                      } flex items-center gap-2 font-medium `}
                    onClick={() => {
                      handleAddPartTimeSession(0);
                    }}
                  >
                    {" "}
                    <IoIosAddCircleOutline className="w-6 h-6" />
                    Add
                  </button>
                )}
              </div>
            </div>
          )}
          {isEdit && (
            <div>
              <button
                onClick={handleSaveClick}
                className={`bg-primary text-white rounded-lg h-[58px] px-6 min-w-[125px] ${isDisabled ? "opacity-50" : ""
                  }`}
                disabled={isDisabled}
              >
                Save Part-time Session Timings
              </button>
              <button
                onClick={handleCancel}
                className=" text-black rounded-lg h-[58px] ml-2 px-6 min-w-[125px] border-secondary border"
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PartTimeSession;
