import { FC, useEffect, useState } from "react";
import { CiCirclePlus } from "react-icons/ci";
import { MdInfoOutline } from "react-icons/md";
import ReactDatePicker from "../../../shared/components/reactDatePicker/ReactDatePicker";
import RequiredAsterisk from "../../../shared/components/ui/RequiredAsterisk";
import {
  formatDateSlashFullYear,
  revertToDateType,
} from "../../../shared/utils/formatDate";
import { timeOptions } from "../../constants/constantValues";
import {
  initBAProgramInfo,
  initSummerProgram,
} from "../../constants/initialState";
import {
  IElementarySchool,
  IOptionN,
} from "../../types/ICenterSetup.interface";
import TimeDropdown from "../dropdowns/TimeDropdown";
import InputField from "../inputsFields/InputField";
import ReactSelect from "../reactSelect/ReactSelect";
import { RiDeleteBin3Line } from "react-icons/ri";
import { TooltipComponent } from "../../../shared/components/tooltip/Tooltip";
import {
  IPartTimeSessionsClassDetailed,
  IProgramClassrooms,
} from "../../types/IClassroomSetup.interface";

const numOfClassroomsOptions: IOptionN[] = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
];
const tooltips = {
  baProgramTitle: {
    id: "baProgramTitle",
    content: `<p></p>`,
  },
  summerStartDate: {
    id: "summerStartDate",
    content: `<p>Please choose the Monday of the week the Summer Program begins – even if it begins in the middle of the week.</p>`,
  },
  summerEndDate: {
    id: "summerEndDate",
    content: `<p>End Date will be calculated. It should end on a Friday</p>`,
  },
  summerWeeks: {
    id: "summerWeeks",
    content: `<p>Please choose how many weeks the Summer Program will last. If it ends in the middle of the week, make sure you include that week.</p>`,
  },
  summerActivity: {
    id: "summerActivity",
    content: `<p>Here you can identify different programs during the Summer. For example, Math Club, English Club, Chess Club etc. You will then be able to assign children to each program.</p>`,
  },
  summerClassrooms: {
    id: "summerClassrooms",
    content: `<p></p>`,
  },
};
interface Props {
  hasBAProgram: boolean;
  hasSummerProgram: boolean;
  baProgramInfo: typeof initBAProgramInfo;
  summerProgramInfo: typeof initSummerProgram;
  specialClassroomsErrors: string[];
  toggleBAProgram: () => void;
  toggleSummerProgram: () => void;
  handleInputBaProgram: (
    name: keyof typeof initBAProgramInfo,
    value: string | number,
    subKey?:
      | keyof IPartTimeSessionsClassDetailed
      | keyof IProgramClassrooms
      | keyof IElementarySchool,
    index?: number
  ) => void;
  handleInputSummerProgram: (
    name: keyof typeof initSummerProgram,
    value: string | number,
    subKey?: keyof IProgramClassrooms,
    index?: number
  ) => void;
  addElementarySchool: () => void;
  removeElementarySchool: (index: number) => void;
  addActivity: () => void;
  removeActivity: (index: number) => void;
  handleSaveBAProgram: () => void;
  handleCancelProgram: () => void;
}

const BeforeAfterSchoolProgram: FC<Props> = ({
  hasBAProgram,
  hasSummerProgram,
  baProgramInfo,
  summerProgramInfo,
  specialClassroomsErrors,
  toggleBAProgram,
  toggleSummerProgram,
  handleInputBaProgram,
  handleInputSummerProgram,
  addElementarySchool,
  removeElementarySchool,
  addActivity,
  removeActivity,
  handleSaveBAProgram,
  handleCancelProgram,
}) => {
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const validateRequiredFields = () => {
    if (specialClassroomsErrors?.length > 0) {
      return true;
    }
    if (!hasBAProgram && !hasSummerProgram) {
      return true; // If neither program is selected, validation is complete
    }
    if (hasBAProgram) {
      const isBAProgramInvalid =
        !baProgramInfo.numberOfClassrooms || // Check if the number of classrooms is set
        !baProgramInfo.classrooms.length || // Ensure classrooms array is populated
        baProgramInfo.classrooms.some(
          (classroom) => !classroom.classroomName?.trim()
        ) || // Ensure each classroom name is filled
        baProgramInfo.partTimeSlots.some(
          (slot) =>
            !slot.name || !slot.startTime?.trim() || !slot.endTime?.trim() // Check each slot for valid startTime and endTime
        );

      if (isBAProgramInvalid) return true;
    }

    if (hasSummerProgram) {
      const isSummerProgramInvalid =
        !summerProgramInfo.summerStartDate || // Ensure start date is set
        !summerProgramInfo.numberOfWeeks || // Ensure number of weeks is set
        // !summerProgramInfo.activities.length || // Ensure activities array is not empty
        summerProgramInfo.activities.some((activity) => !activity?.trim()) || // Ensure each activity name is filled
        !summerProgramInfo.numberOfClassrooms || // Check if number of classrooms is set
        !summerProgramInfo.classrooms.length || // Ensure classrooms array is populated
        summerProgramInfo.classrooms.some(
          (classroom) => !classroom.classroomName?.trim()
        ); // Ensure each classroom name is filled

      if (isSummerProgramInvalid) return true;
    }

    return false; // Return false if all validations pass
  };

  useEffect(() => {
    console.log("return of validity: ", validateRequiredFields());
    setIsSaveDisabled(validateRequiredFields());
  }, [
    hasBAProgram,
    hasSummerProgram,
    baProgramInfo,
    summerProgramInfo,
    specialClassroomsErrors,
  ]);

  return (
    <>
      <div className="bg-white p-6 rounded-xl mb-6">
        <h2 className="text-xl text-secondary font-semibold mb-2 flex items-start gap-2">
          Before/After School Program{" "}
          <span data-tooltip-id={tooltips.baProgramTitle.id}>
            <MdInfoOutline className="text-primary w-[18px] h-[18px]" />
          </span>
          <TooltipComponent
            id={tooltips.baProgramTitle.id}
            content={tooltips.baProgramTitle.content}
            isWhite={true}
          />
        </h2>
        <span className="text-base text-secondary font-medium mb-4 block">
          Do you have a Before/After school program in your center?
        </span>
        <div className="flex items-center gap-6 mb-6">
          <div className="flex items-center gap-2">
            <input
              type="radio"
              className="w-5 h-5 border-2 border-gray-400 appearance-none rounded-full flex items-center justify-center checked:border-primary focus:outline-none checked:bg-white checked:before:content-[''] checked:before:block checked:before:rounded-full checked:before:bg-primary checked:before:w-3 checked:before:h-3 cursor-pointer"
              checked={hasBAProgram}
              onChange={toggleBAProgram}
            />
            <label htmlFor="" className="text-base text-secondaryVariant">
              Yes
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="radio"
              className="w-5 h-5 border-2 border-gray-400 appearance-none rounded-full flex items-center justify-center checked:border-primary focus:outline-none checked:bg-white checked:before:content-[''] checked:before:block checked:before:rounded-full checked:before:bg-primary checked:before:w-3 checked:before:h-3 cursor-pointer"
              checked={!hasBAProgram}
              onChange={toggleBAProgram}
            />
            <label htmlFor="" className="text-base text-secondaryVariant">
              No
            </label>
          </div>
        </div>
        <div className="mb-6">
          <h3 className="text-sm text-secondary mb-2">
            Elementary schools served
          </h3>
          <div>
            <button
              className="text-base text-statusColor flex items-center"
              onClick={addElementarySchool}
            >
              <CiCirclePlus className="w-6 h-6" />
              Add
            </button>
          </div>
        </div>
        <div className="grid grid-cols-7 gap-6 mb-6 items-end">
          {baProgramInfo?.elementarySchools?.length > 0 &&
            baProgramInfo?.elementarySchools?.map((school, index) => (
              <div className="flex items-center gap-2" key={index}>
                <InputField
                  label={`School ${index + 1}`}
                  name="schoolName"
                  type="text"
                  placeholder="School Name"
                  // required
                  value={school.schoolName}
                  onChange={(e) => {
                    handleInputBaProgram(
                      "elementarySchools",
                      e.target.value,
                      "schoolName",
                      index
                    );
                  }}
                />
                <span className="bg-white p-1 rounded-full max-w-8 text-center flex items-center justify-center pt-8">
                  <button
                    className="text-dangerAlert"
                    onClick={() => removeElementarySchool(index)}
                  >
                    <RiDeleteBin3Line className="w-6 h-6 " />
                  </button>
                </span>
              </div>
            ))}
        </div>
        {hasBAProgram && (
          <>
            <div className="grid grid-cols-7 gap-6 mb-6">
              <ReactSelect
                key={baProgramInfo.numberOfClassrooms}
                label="No. of Classrooms?"
                placeholder="Select No."
                options={numOfClassroomsOptions}
                value={numOfClassroomsOptions.find(
                  (option) => option.value === baProgramInfo.numberOfClassrooms
                )}
                onChange={(selectedOption) =>
                  handleInputBaProgram(
                    "numberOfClassrooms",
                    selectedOption?.value || 0
                  )
                }
                required
              />
              {baProgramInfo?.classrooms?.length > 0 &&
                baProgramInfo?.classrooms?.map((classroom, index) => (
                  <div>
                    <InputField
                      label="Name"
                      name="classroomName"
                      type="text"
                      placeholder={`Classroom name ${index + 1}`}
                      capitalizeTextClass={true}
                      required
                      value={classroom.classroomName}
                      onChange={(e) => {
                        handleInputBaProgram(
                          "classrooms",
                          e.target.value,
                          undefined,
                          index
                        );
                      }}
                    />
                  </div>
                ))}
            </div>
            {baProgramInfo?.partTimeSlots?.map((slot, index) => (
              <div className="grid grid-cols-7 gap-6 mb-6">
                <div>
                  <InputField
                    label="Name"
                    name="classroomName"
                    type="text"
                    placeholder="Before Program"
                    value={slot.name}
                    onChange={(e) =>
                      handleInputBaProgram(
                        "partTimeSlots",
                        e.target.value,
                        "name",
                        index
                      )
                    }
                    required
                    disabled
                  />
                </div>
                {index !== 2 ? (
                  <>
                    <div>
                      <TimeDropdown
                        times={timeOptions}
                        label="Start Time"
                        required
                        placeholder="Set time"
                        value={slot.startTime}
                        onSelectTime={(selectedTime) =>
                          handleInputBaProgram(
                            "partTimeSlots",
                            selectedTime,
                            "startTime",
                            index
                          )
                        }
                      />{" "}
                    </div>
                    <div>
                      <TimeDropdown
                        times={timeOptions}
                        label="End Time"
                        required
                        placeholder="Set time"
                        value={slot.endTime}
                        onSelectTime={(selectedTime) =>
                          handleInputBaProgram(
                            "partTimeSlots",
                            selectedTime,
                            "endTime",
                            index
                          )
                        }
                      />{" "}
                    </div>
                  </>
                ) : (
                  <div className="w-full col-span-2 text-dangerAlert text-xs">
                    Sessions will be a combination of the above timings
                  </div>
                )}
              </div>
            ))}
            {/* <div className="grid grid-cols-7 gap-6 mb-6">
              <div>
                <InputField
                  label="Name"
                  name="classroomName"
                  type="text"
                  placeholder="After Program"
                  required
                  value={baProgramInfo.afterSchoolProgram.name}
                  onChange={(e) =>
                    handleInputBaProgram(
                      "afterSchoolProgram",
                      e.target.value,
                      "name"
                    )
                  }
                />
              </div>
              <div>
                <TimeDropdown
                  times={timeOptions}
                  label="Start Time"
                  required
                  placeholder="Set time"
                  value={baProgramInfo.afterSchoolProgram.startTime}
                  onSelectTime={(selectedTime) =>
                    handleInputBaProgram(
                      "afterSchoolProgram",
                      selectedTime,
                      "startTime"
                    )
                  }
                />{" "}
              </div>
              <div>
                <TimeDropdown
                  times={timeOptions}
                  label="End Time"
                  required
                  placeholder="Set time"
                  value={baProgramInfo.afterSchoolProgram.endTime}
                  onSelectTime={(selectedTime) =>
                    handleInputBaProgram(
                      "afterSchoolProgram",
                      selectedTime,
                      "endTime"
                    )
                  }
                />{" "}
              </div>
            </div>
            <div className="grid grid-cols-7 gap-6 mb-6 items-center">
              <div>
                <InputField
                  label="Name"
                  name="classroomName"
                  type="text"
                  placeholder="B/A Program"
                  required
                  value={baProgramInfo.beforeAfterSchoolProgram.name}
                  onChange={(e) =>
                    handleInputBaProgram(
                      "beforeAfterSchoolProgram",
                      e.target.value,
                      "name"
                    )
                  }
                />
              </div>
              <div className="col-span-2 text-xs text-dangerAlert">
                Sessions will be a combination of the above timings
              </div>
            </div> */}
          </>
        )}
        <div>
          <span className="text-base text-secondary font-medium mb-4 block">
            Do you have summer program in your center?
          </span>
          <div className="flex items-center gap-6 mb-6">
            <div className="flex items-center gap-2">
              <input
                type="radio"
                className="w-5 h-5 border-2 border-gray-400 appearance-none rounded-full flex items-center justify-center checked:border-statusColor focus:outline-none checked:bg-white checked:before:content-[''] checked:before:block checked:before:rounded-full checked:before:bg-statusColor checked:before:w-3 checked:before:h-3 cursor-pointer"
                checked={hasSummerProgram}
                onChange={toggleSummerProgram}
              />
              <label htmlFor="" className="text-base text-secondaryVariant">
                Yes
              </label>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="radio"
                className="w-5 h-5 border-2 border-gray-400 appearance-none rounded-full flex items-center justify-center checked:border-statusColor focus:outline-none checked:bg-white checked:before:content-[''] checked:before:block checked:before:rounded-full checked:before:bg-statusColor checked:before:w-3 checked:before:h-3 cursor-pointer"
                checked={!hasSummerProgram}
                onChange={toggleSummerProgram}
              />
              <label htmlFor="" className="text-base text-secondaryVariant">
                No
              </label>
            </div>
          </div>
          {hasSummerProgram && (
            <>
              <div className="grid grid-cols-7 gap-6 mb-6">
                <div>
                  <label className="text-base text-secondaryVariant flex items-start gap-2 mb-1">
                    <span>
                      {"Start Date"}
                      <RequiredAsterisk />
                    </span>
                    <span data-tooltip-id={tooltips.summerStartDate.id}>
                      <MdInfoOutline className="text-primary w-[18px] h-[18px]" />
                    </span>
                    <TooltipComponent
                      id={tooltips.summerStartDate.id}
                      content={tooltips.summerStartDate.content}
                      isWhite={true}
                    />
                  </label>
                  <ReactDatePicker
                    key={summerProgramInfo.summerStartDate}
                    placeholder="Select Date"
                    value={revertToDateType(summerProgramInfo.summerStartDate)}
                    onChange={(date) =>
                      handleInputSummerProgram(
                        "summerStartDate",
                        formatDateSlashFullYear(date || "") || ""
                      )
                    }
                    isMonday
                  />
                </div>
                <div>
                  <InputField
                    key={summerProgramInfo.numberOfWeeks}
                    min={1}
                    label="Weeks"
                    name="classroomName"
                    type="number"
                    placeholder="After Program"
                    showInfoIcon
                    required
                    value={summerProgramInfo.numberOfWeeks || undefined}
                    onChange={(e) =>
                      handleInputSummerProgram(
                        "numberOfWeeks",
                        Number(e.target.value)
                      )
                    }
                    tooltipId={tooltips.summerWeeks.id}
                    tooltipContent={tooltips.summerWeeks.content}
                  />
                </div>
                <div>
                  <label className="text-base text-secondaryVariant flex items-start gap-2 mb-1">
                    <span>
                      {"End Date"}
                      <RequiredAsterisk />
                    </span>
                    <span data-tooltip-id={tooltips.summerEndDate.id}>
                      <MdInfoOutline className="text-primary w-[18px] h-[18px]" />
                    </span>
                    <TooltipComponent
                      id={tooltips.summerEndDate.id}
                      content={tooltips.summerEndDate.content}
                      isWhite={true}
                    />
                  </label>
                  <ReactDatePicker
                    key={summerProgramInfo.summerEndDate}
                    placeholder="End Date"
                    value={revertToDateType(
                      summerProgramInfo.summerEndDate || ""
                    )}
                    onChange={(date) =>
                      handleInputSummerProgram(
                        "summerStartDate",
                        formatDateSlashFullYear(date || "") || ""
                      )
                    }
                    disabled
                  />
                </div>
              </div>
              <h3 className="text-base text-secondary font-medium mb-4 flex items-start gap-2">
                Summer Program Activities{" "}
                <span data-tooltip-id={tooltips.summerActivity.id}>
                  <MdInfoOutline className="text-primary w-[18px] h-[18px]" />
                </span>
                <TooltipComponent
                  id={tooltips.summerActivity.id}
                  content={tooltips.summerActivity.content}
                  isWhite={true}
                />
              </h3>
              <div className="grid grid-cols-7 gap-6 mb-6 items-end">
                {summerProgramInfo?.activities?.length > 0 &&
                  summerProgramInfo?.activities?.map((activity, index) => (
                    <div className="flex items-center gap-2" key={index}>
                      <InputField
                        label={`Activity ${index + 1}`}
                        name="classroomName"
                        type="text"
                        placeholder="After Program"
                        // required
                        value={activity}
                        onChange={(e) => {
                          handleInputSummerProgram(
                            "activities",
                            e.target.value,
                            undefined,
                            index
                          );
                        }}
                      />
                      <span className="bg-white p-1 rounded-full max-w-8 text-center flex items-center justify-center">
                        <button
                          className="text-dangerAlert"
                          onClick={() => removeActivity(index)}
                        >
                          <RiDeleteBin3Line className="w-6 h-6 " />
                        </button>
                      </span>
                    </div>
                  ))}
                <div>
                  <button
                    className="text-base text-statusColor flex items-center flex-col"
                    onClick={addActivity}
                  >
                    <CiCirclePlus className="w-6 h-6" />
                    Add Activity
                  </button>
                </div>
              </div>
              <h3 className="text-base text-secondary font-medium mb-4 flex items-start gap-2">
                How many Summer Program classrooms?{" "}
                <span data-tooltip-id={tooltips.summerClassrooms.id}>
                  <MdInfoOutline className="text-primary w-[18px] h-[18px]" />
                </span>
                <TooltipComponent
                  id={tooltips.summerClassrooms.id}
                  content={tooltips.summerClassrooms.content}
                  isWhite={true}
                />
              </h3>
              <div className="grid grid-cols-7 gap-6 mb-6">
                <div>
                  <ReactSelect
                    key={summerProgramInfo.numberOfClassrooms}
                    label="Classrooms?"
                    placeholder="Select No."
                    options={numOfClassroomsOptions}
                    value={numOfClassroomsOptions.find(
                      (option) =>
                        option.value === summerProgramInfo.numberOfClassrooms
                    )}
                    onChange={(selectedOption) =>
                      handleInputSummerProgram(
                        "numberOfClassrooms",
                        selectedOption?.value || 0
                      )
                    }
                    required
                  />
                </div>
                {summerProgramInfo?.classrooms?.length > 0 &&
                  summerProgramInfo?.classrooms?.map((classroom, index) => (
                    <div>
                      <InputField
                        label="Name"
                        name="classroomName"
                        type="text"
                        placeholder={`Classroom name ${index + 1}`}
                        required
                        value={classroom.classroomName}
                        onChange={(e) => {
                          handleInputSummerProgram(
                            "classrooms",
                            e.target.value,
                            "classroomName",
                            index
                          );
                        }}
                      />
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
        {specialClassroomsErrors?.length > 0 && (
          <div className="mb-3">
            {specialClassroomsErrors?.map((error, index) => (
              <p key={index} className="text-dangerAlert">
                {error}
              </p>
            ))}
          </div>
        )}
        <div className="flex items-center gap-4 ">
          <button
            className={`btnPrimary max-w-[125px] ${
              isSaveDisabled ? "opacity-50" : ""
            }`}
            onClick={handleSaveBAProgram}
            disabled={isSaveDisabled}
          >
            Save
          </button>
          <button
            className="btnSimple max-w-[125px]"
            onClick={handleCancelProgram}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default BeforeAfterSchoolProgram;
